/**
 * Spotlight
 *
 * @see https://codepen.io/nathantaylor/pen/WowbBE
 */
(function ($) {
    'use strict';

    var container = $('.js-reveal');
    var slides = container.find('.c-reveal__item');

    // fix height
    var containerHeight = 0;
    slides.each(function () {
        var slideHeight = $(this).innerHeight();
        if (slideHeight > containerHeight) {
            containerHeight = slideHeight;
        }
    });

    container.css('height', containerHeight);

    linePage();
    cycleText();

    function linePage() {
        slides.each(function (index) {
            var slide = $(this);
            var description = slide.find('h1, .h1');
            var text = $.trim(description.html());
            var output = '';

            //split all letters into spans
            var inTag = false;
            var textLength = 0;
            for (var i = 0, len = text.length; i < len; i++) {
                if (text[i] === '<') {
                    inTag = true;
                }

                if (inTag) {
                    output += text[i];
                } else {
                    output += '<span data-index="' + textLength + '">' + text[i] + '</span>';
                    textLength++;
                }

                if (text[i] === '>') {
                    inTag = false;
                }
            }

            //put it in the html
            description.html(output);

            // fix <strong>, <em>, etc...
            description.find('strong, em').each(function () {
                var tag = $(this);
                tag.find('span').wrapInner('<' + this.tagName + ' />');
                tag.replaceWith(tag.html());
            });

            //check the offset of each letter to figure out where the line breaks
            var prev = 0;
            var parts = [];
            description.find('span').each(function (i) {
                var spanOffsetTop = $(this).offset().top;

                if (spanOffsetTop > prev) {
                    parts.push(i);
                    prev = spanOffsetTop;
                }
            });

            parts.push(textLength);

            // create final
            // var finalOutput = '';
            var start = 0;
            var lastLine = 0;
            parts.forEach(function (firstCharOfNextLine, line) {
                if (firstCharOfNextLine <= 0) {
                    return;
                }

                var selector = '';
                for (var i = start; i < firstCharOfNextLine; i++) {
                    selector += 'span[data-index="' + i + '"], ';
                }

                selector = selector.slice(0, -2);
                start = firstCharOfNextLine;

                description.find(selector).wrapAll('<span data-line="' + line + '" class="c-reveal__line" />');

                lastLine = line;
            });

            slide.find('.c-btn')
                .wrapAll('<span data-line="' + (lastLine + 1) + '" class="c-reveal__line" />')
                .wrapAll('<span />');
        })
    }

    function cycleText() {
        var current = 0;
        var next = 1;
        setInterval(function () {
            slides.eq(current).removeClass('-show').addClass('-hide');
            slides.eq(next).removeClass('-hide').addClass('-show');

            current++;
            next++;

            if (current >= slides.length) {
                current = 0;
            }

            if (next >= slides.length) {
                next = 0;
            }
        }, 4000);
    }


}(jQuery));