/**
 * Sidebar
 *
 * @see https://codepen.io/jackcohle/pen/mjryyq
 */

(function ($) {
    'use strict';

    /**
     * Multilevel Menu
     */
    (function () {
        var submenu = $('.js-submenu');

        submenu.find('.js-submenuDropdown').click(function (e) {
            var submenuItem = $(this).closest('li');

            if (submenuItem.find('ul').length > 0) {
                e.preventDefault();

                if (submenuItem.hasClass('is-selected')) {
                    submenuItem.removeClass('is-selected').find('li').removeClass('is-selected');
                    submenuItem.find('ul').slideUp(400);
                } else {

                    if (submenuItem.parents('li.is-selected').length === 0) {
                        submenu.find('li').removeClass('is-selected');
                        submenu.find('ul').slideUp(400);
                    } else {
                        submenuItem.parent().find('li').removeClass('is-selected');
                        submenuItem.parent().find('> li ul').slideUp(400);
                    }

                    submenuItem.addClass('is-selected');
                    submenuItem.find('>ul').slideDown(400);
                }
            }
        });


        submenu.find('ul').each(function (i) {
            var paddingLeft = $(this).parent().parent().find('> li > a').css('padding-left');
            var pIntPLeft = parseInt(paddingLeft);
            var result = pIntPLeft + 20;

            if ($(this).find('> li > ul').length > 0) {
                $(this).find('> li > a').css('padding-left', result);
            } else {
                $(this).find('> li > a').css('padding-left', result).parent().addClass('is-selected--last');
            }
        });

        var t = ' li > ul ';

        for (var i = 1; i <= 10; i++) {
            submenu.find('> ' + t.repeat(i)).addClass('subMenuColor' + i);
        }

        var activeLi = $('li.is-selected');
        //console.log(activeLi);

        if (activeLi.length) {
            opener(activeLi);
        }

        function opener(li) {
            var parent = li.closest('ul');

            li.addClass('is-selected');
            parent.addClass('is-open');

            if (parent.closest('li').length) {
                opener(parent.closest('li'));
            } else {
                return false;
            }

            // Apro eventuali sottomenu
            li.find('> ul').addClass('is-open');

        }
    }());

}(jQuery));
