/**
 * Back To Top
 *
 * @requires libraries/util.js
 *
 * @see https://codyhouse.co/ds/components/app/back-to-top
 */

(function () {
    var backTop = document.getElementsByClassName('js-backtotop')[0];

    function checkBackToTop() {
        var windowTop = window.scrollY || document.documentElement.scrollTop;
        Util.toggleClass(backTop, 'is-visible', windowTop >= scrollOffset);
        scrolling = false;
    }

    if (backTop) {
        var scrollDuration = parseInt(backTop.getAttribute('data-duration')) || 300; // scroll to top duration
        var scrollOffset = parseInt(backTop.getAttribute('data-offset')) || 0; // show backtotop if scrolling > scrollOffset
        var scrolling = false;

        // detect click on backtotop link
        backTop.addEventListener('click', function (event) {
            event.preventDefault();
            (!window.requestAnimationFrame) ? window.scrollTo(0, 0) : Util.scrollTo(0, scrollDuration);
            // move the focus to the #top-element - don't break keyboard navigation
            Util.moveFocus(document.getElementById(backTop.getAttribute('href').replace('#', '')));
        });

        // listen to the window scroll and update backtotop visibility
        checkBackToTop();
        if (scrollOffset > 0) {
            window.addEventListener("scroll", function (event) {
                if (!scrolling) {
                    scrolling = true;
                    (!window.requestAnimationFrame) ? setTimeout(function () {
                        checkBackToTop();
                    }, 250) : window.requestAnimationFrame(checkBackToTop);
                }
            });
        }
    }
}());