/**
 * Swiper
 */
(function () {
    const progressCircle = document.querySelector(".js-slideshow-autoplay svg");
    const progressContent = document.querySelector(".js-slideshow-autoplay span");

    const swiper = new Swiper(".js-slideshow", {
        autoHeight: true,
        spaceBetween: 0,
        effect: "fade",
        centeredSlides: "true",
        autoplay: {
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        },
        pagination: false,
        navigation: false,
        on: {
            autoplayTimeLeft(s, time, progress) {
                progressCircle.style.setProperty("--progress", 1 - progress);
                progressContent.textContent = `${Math.ceil(time / 1000)}s`;
            }
        }
    });
}());
