/**
 * Filters
 *
 * @author Daniele De Nobili
 */

(function ($) {
    "use strict";

    /**
     * Infinite Scroll
     *
     * @requires plugins/infinite-scroll.pkgd.js
     */
    (function () {
        // Verifico se esiste la paginazione
        if (!$('.c-pagination').length) {
            return;
        }

        var last = $('.c-pagination__url--last');
        var url = last.attr('href');
        var urlSegments = url.split('?');
        var urlBase = urlSegments[0].replace(/page\/([0-9]+)\//gi, 'page/{page}/');
        var urlQueryString = urlSegments[1] || '';

        $('.js-filters-entries').infiniteScroll({
            path: function () {
                console.log(this.pageIndex);

                var next = urlBase.replace('{page}', this.pageIndex + 1);

                if (urlQueryString) {
                    next += '?' + urlQueryString;
                }

                console.log(next);

                return next;
            },
            append: '.c-entry',
            //button: '.js-load-contents-more', // using button, disable loading on scroll
            //scrollThreshold: false,
            status: '.js-load-contents-status',
            history: false
        });
    }());

}(jQuery));