/**
 * Supports
 *
 * 01. SVG
 * 02. Object-Fit Image
 * 03. Lazy iframe
 * 04. Facebook SDK
 */
(function (w, d) {
    /**
     * SVG
     *
     * @see {@link libraries/svg4everybody.js}
     */
    svg4everybody();


    /**
     * Object-Fit Image
     *
     * @see {@link libraries/ofi.js}
     */
    objectFitImages();


    /**
     * Lazy iframe
     * Con verifica accettazione cookie
     *
     * @see {@link libraries/util.js}
     */
    w.onCookiePolicyAccepted(function () {
        var iframe = d.getElementsByTagName('iframe')[0];

        if (iframe) {
            Util.addClass(iframe, 'lazyload');
        }
    });
}(window, document));
