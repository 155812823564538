/**
 * Gallery
 *
 * @see {@link plugins/jquery.magnific-popup.js}
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    $('.js-inline-modal').magnificPopup({
        type: 'inline',
        fixedContentPos: false,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: false,
        preloader: false,
        midClick: true,
        removalDelay: 300,
        mainClass: 'mfp-slide-bottom'
    });

    $(document).on('click', '.popup-modal-dismiss', function (e) {
        e.preventDefault();
        $.magnificPopup.close();
    });

}(jQuery));
