/**
 * Sticky Share
 *
 * @requires libraries/util.js
 *
 * @see https://codyhouse.co/ds/components/app/back-to-top
 */

(function () {
    var stickyShare = document.getElementsByClassName('js-share')[0];

    function checkStickyShare() {
        var windowTop = window.scrollY || document.documentElement.scrollTop;
        Util.toggleClass(stickyShare, 'is-visible', windowTop >= scrollOffset);
        scrolling = false;
    }

    if (stickyShare) {
        var scrollDuration = parseInt(stickyShare.getAttribute('data-duration')) || 300; // scroll to top duration
        var scrollOffset = parseInt(stickyShare.getAttribute('data-offset')) || 0; // show backtotop if scrolling > scrollOffset
        var scrolling = false;

        // detect click on backtotop link
        stickyShare.addEventListener('click', function (event) {
            event.preventDefault();
            (!window.requestAnimationFrame) ? window.scrollTo(0, 0) : Util.scrollTo(0, scrollDuration);
            // move the focus to the #top-element - don't break keyboard navigation
            Util.moveFocus(document.getElementById(stickyShare.getAttribute('href').replace('#', '')));
        });

        // listen to the window scroll and update backtotop visibility
        checkStickyShare();
        
        if (scrollOffset > 0) {
            window.addEventListener("scroll", function (event) {
                if (!scrolling) {
                    scrolling = true;
                    (!window.requestAnimationFrame) ? setTimeout(function () {
                        checkStickyShare();
                    }, 250) : window.requestAnimationFrame(checkStickyShare);
                }
            });
        }
    }
}());