/**
 * Header Scroll Hidden/Visible
 *
 * @requires libraries/util.js
 *
 * @see https://codyhouse.co/ds/components/app/back-to-top
 */
(function () {
    var header = document.getElementsByClassName('js-header')[0];

    function checkHeaderVisible() {
        var windowTop = window.scrollY || document.documentElement.scrollTop;
        var headerHeight = header.offsetHeight;

        Util.toggleClass(header, 'is-visible', windowTop >= scrollOffset);
        Util.toggleClass(header, 'is-fixed', windowTop > 1 && windowTop < headerHeight);
        Util.toggleClass(header, 'is-hidden', windowTop > headerHeight && windowTop < scrollOffset);
        scrolling = false;
    }

    if (header) {
        var scrollOffset = parseInt(header.getAttribute('data-offset')) || 0; // show header if scrolling > scrollOffset
        var scrolling = false;

        // listen to the window scroll and update header visibility
        checkHeaderVisible();

        if (scrollOffset > 0) {
            window.addEventListener("scroll", function (event) {
                if (!scrolling) {
                    scrolling = true;
                    (!window.requestAnimationFrame) ? setTimeout(function () {
                        checkHeaderVisible();
                    }, 250) : window.requestAnimationFrame(checkHeaderVisible);
                }
            });
        }
    }
}());